// src/components/Container.js
import React from 'react';
import './BottomNav.css';
import SmallLogo from '../assets/BlackBackLogo.png'

const BottomNav = () => {
  return (
    <div className="container">
        <div className='BottomNav-main-selection'>
            <ul className='BotoomNav-main-options'>
                <li className='BotoomNav-main-options-li'>
                    <a href="products">Products</a>
                </li>
                <li className='BotoomNav-main-options-li'>
                    <a href="pricing">Pricing</a>
                </li>
                <li className='BotoomNav-main-options-li'>
                    <a href="about-us">About</a>
                </li>
                <li className='BotoomNav-main-options-li'>
                    <a href="contact-us">Contact</a>
                </li>
            </ul>
        </div> 
        {/* <div className="selection">
            <ul className='selection1'>
                <li>PimaSens</li>
                <li>PimaSens</li>
                <li>Resources</li>
                <li>Support</li>
            </ul>
            <ul className='selection2'>
                <li>PimaSpec</li>
                <li>PimaSpec</li>
                <li>Mission</li>
                <li>Sales</li>
            </ul>
            <ul className='selection3'>
                <li>Brand</li>
                <li>Documentation</li>
            </ul>
            <ul className='selection4'>
                <li>Privacy</li>
                <li>Policy</li>
            </ul>
        </div> */}
        <img src={SmallLogo} alt="" className='small-logo'/>
    </div>
  );
};

export default BottomNav;
