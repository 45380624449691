// src/components/Navbar.js
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NavBar.css';
import SmallLogo from '../assets/BlueBackLogo.png'
import SignUpPage from "../pages/SignUpPage";

const NavBar = () => {
    const [isActive, setIsActive] = useState(false);

    const navigate = useNavigate();

    const handleLoginClick = () => {
      navigate('/login');
    };

    const handleSignUpClick = () => {
      navigate('/sign-up');
    };

  return (
    <div className="navbar">
        <Link to="/home">
            <img src={SmallLogo} alt="" className="menu-icon" />
        </Link>
        <div className="nav-items">
            <ul className="nav-options">
            <li className="nav-option-li first">
                <a href="products">Products</a>
            </li>

            <li className="nav-option-li">
                <a href="pricing">Pricing</a>
            </li>

            <li className="nav-option-li">
                <a href="about-us">About</a>
            </li>

            <li className="nav-option-li last">
                <a href="contact-us">Contact</a>
            </li>
            </ul>
        </div>
        <div className="nav-buttons">
        <button className="nav-button login" onClick={handleLoginClick}>Log In</button>
        <button className="nav-button signup" onClick={handleSignUpClick}>Sign Up</button>
      </div>
    </div>
  );
};

export default NavBar;
