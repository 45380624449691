import React from 'react';
import './ContactBox.css'; // Import the CSS file for styling

const ContactBox = () => {
    return (
        <div className="ContactBox">
            <h1 className='support-header'>Support</h1>
            <h1 className='support-text'>Our team is here for any questions or support. Contact us at:</h1>
            <div className="support-box">
            <a className='support-box' href="mailto:pimasensinc@gmail.com">pimasensinc@gmail.com</a>
            </div>
            
        </div>
    );
};

export default ContactBox;
