import React from 'react';
import './ComingSoonPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ComingSoonPage = () => {
    return (
      <div className="ComingSoon">
        <NavBar />
        <div className='ComingSoon-box'>
            <h1 className='ComingSoon-header'>Under Devolopment</h1>
        </div>
        <BottomNav />
    </div>
  );
};

export default ComingSoonPage;