import React from 'react';
import './Home.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import HomeLogo from '../assets/HomeLogo.png'
import ArrowDown  from '../assets/ArrowDown.png'
import pimasens1 from '../assets/pimasensScreenshots/pimasens1.png';
import pimasens2 from '../assets/pimasensScreenshots/pimasens2.png';
import pimasens3 from '../assets/pimasensScreenshots/pimasens3.png';
import pimasens4 from '../assets/pimasensScreenshots/pimasens4.png';
import pimasens5 from '../assets/pimasensScreenshots/pimasens5.png';
import pimasens6 from '../assets/pimasensScreenshots/pimasens6.png';
import pimasens7 from '../assets/pimasensScreenshots/pimasens7.png';
import pimaspec1 from '../assets/pimaspecScreenshots/pimaspec1.png';
import pimaspec2 from '../assets/pimaspecScreenshots/pimaspec2.png';
import pimaspec3 from '../assets/pimaspecScreenshots/pimaspec3.png';
import pimaspec4 from '../assets/pimaspecScreenshots/pimaspec4.png';
import pimaspec5 from '../assets/pimaspecScreenshots/pimaspec5.png';
import pimaspec6 from '../assets/pimaspecScreenshots/pimaspec6.png';



const Home = () => {
  return (
    <div className="home">
        <NavBar />
        <h1 className='header1'>Engage more</h1>
        <h1 className='header2'>Intelligently</h1>
        <p className="header-info">
        Fueled by the drive to create better outcomes, our advanced platforms offer precise monitoring,
        empowering you to make informed decisions confidently.
      </p>
      {/*Buttons*/}
      {/* 
      <div className="button-container">
        <button className="book-button demo">Book a demo</button>
        <button className="book-button trail">Start a trial</button>
      </div>
      */}
      <img src={ArrowDown} alt="ArrowDown" className="down-arrow" />
      <img src={HomeLogo} alt="LOGO" className="top-right-image" />

      {/* pimaSens section*/}

      <h2 className='header3'>Enhance Your Well-Being with </h2>
      <h1 className='header4'>Zenro</h1>
      <p className='pimasens-paragraph p1'>PimaSens offers a comprehensive platform for precise monitoring, personalized insights, and seamless integration with healthcare providers. 
      Track your cortisol levels and other health metrics effortlessly, and share your data with your doctor to make informed decisions about your health.</p>
      <h3 className='pimasens-header h1'>TRACKING</h3>
      <p className='pimasens-paragraph p2'>Track your cortisol levels and other health metrics effortlessly</p>
      <h3 className='pimasens-header h2'>PERSONALIZED INSIGHTS</h3>
      <p className='pimasens-paragraph p3'>Our framework delivers tailored recommendations using real-time biometric data to support individual mental health needs</p>
      <h3 className='pimasens-header h3'>RECCOMENDATIONS</h3>
      <p className='pimasens-paragraph p4'>Our AI recommendations integrate DSM guidelines with real-time cortisol and adrenaline tracking to deliver personalized mental health insights</p>
      <h3 className='pimasens-header h4'>INTEGRATION</h3>
      <p className='pimasens-paragraph p5'>Seamlessly share your health data and connect with our sensors</p>

      {/* pimaSpec section*/}
{/* 
      <h2 className='header5'>Optimize Your Testing with</h2>
      <h1 className='header6'>Agrilo</h1>
      <p className='pimaspec-paragraph p1'>PimaSens offers a comprehensive platform for precise monitoring, personalized insights, and seamless integration with healthcare providers. 
      Track your cortisol levels and other health metrics effortlessly, and share your data with your doctor to make informed decisions about your health.</p>
      <h3 className='pimaspec-header h1'>TRACKING</h3>
      <p className='pimaspec-paragraph p2'>Track your cortisol levels and other health metrics effortlessly</p>
      <h3 className='pimaspec-header h2'>PERSONALIZED INSIGHTS</h3>
      <p className='pimaspec-paragraph p3'>Track your cortisol levels and other health metrics effortlessly</p>
      <h3 className='pimaspec-header h3'>RECCOMENDATIONS</h3>
      <p className='pimaspec-paragraph p4'>Track your cortisol levels and other health metrics effortlessly</p>
      <h3 className='pimaspec-header h4'>INTEGRATION</h3>
      <p className='pimaspec-paragraph p5'>Track your cortisol levels and other health metrics effortlessly</p>
      pimaSpec section*/}
      <img src={pimasens1} alt="shot1" className="pimasens1" />
      <img src={pimasens2} alt="shot2" className="pimasens2" />
      <img src={pimasens3} alt="shot3" className="pimasens3" />
      <img src={pimasens4} alt="shot4" className="pimasens4" />
      <img src={pimasens5} alt="shot5" className="pimasens5" />
      <img src={pimasens6} alt="shot6" className="pimasens6" />
      <img src={pimasens7} alt="shot7" className="pimasens7" />

      {/*
      <img src={pimaspec1} alt="shot8" className="pimaspec1" />
      <img src={pimaspec2} alt="shot9" className="pimaspec2" />
      <img src={pimaspec3} alt="shot10" className="pimaspec3" />
      <img src={pimaspec4} alt="shot11" className="pimaspec4" />
      <img src={pimaspec5} alt="shot12" className="pimaspec5" />
      <img src={pimaspec6} alt="shot13" className="pimaspec6" />
      pimaSpec section*/}
      <BottomNav />
    </div>
  );
};

export default Home;
