import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { Username, access} = location.state || {};
  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (access) {
        try {
          const response = await fetch('https://www.pimasens.com/getUser/', {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer'+access,
            },
          });

          const result = await response.json();

          if (response.ok) {
            setApiData(result);
          } else {
            setError(result.message || 'An error occurred');
          }
        } catch (err) {
          setError('An error occurred');
        }
      }
    };

    fetchData();
  }, [access]);

  const handleHomeClick = () => {
    navigate('/home');
  };

  const backgroundColor = error ? 'orange' : 'green';

  return (
    <div style={{ 
      backgroundColor, 
      height: '100vh', 
      color: 'white', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center' 
    }}>
      {error ? (
        <h1>Error: {error}</h1>
      ) : (
        <>
          <h1>Success</h1>
          {Username && <p>Welcome, {Username}!</p>}
          {apiData && <p>API Data: {JSON.stringify(apiData)}</p>}
        </>
      )}
      <button onClick={handleHomeClick}>Home</button>
    </div>
  );
};

export default SuccessPage;
