import React from 'react';
import './ContactPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ContactPage = () => {
    return (
      <div className="Contact">
        <NavBar />
        <h1 className='ContactHeader'>Contact</h1>
        <ContactBox />
        <BottomNav />
    </div>
  );
};

export default ContactPage;